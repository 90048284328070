import React, { useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'

const gradeOptions = [
  { value: 'P', label: 'P' },
  { value: 'UTK', label: 'UTK' },
  { value: 'K', label: 'K' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface GradePickerProps {
  grade: string
  setGrade: (grade: string | undefined) => void
}

const GradePicker: React.FC<GradePickerProps> = ({ grade, setGrade }) => {
  useEffect(() => {
    setGrade(gradeOptions?.[0]?.value)
  }, [])

  return (
    <div>
      <RadioGroup value={grade} onChange={setGrade} className="mt-2">
        <RadioGroup.Label className="sr-only"> Choose a memory option </RadioGroup.Label>
        <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
          {gradeOptions.map((option) => (
            <RadioGroup.Option
              key={option.value}
              value={option.value}
              className={({ active, checked }) =>
                classNames(
                  'cursor-pointer focus:outline-none',
                  active ? 'ring-2 ring-offset-2 ring-sdusd-blue' : '',
                  checked
                    ? 'bg-sdusd-blue border-transparent text-white hover:bg-sdusd-blue'
                    : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                  'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1',
                )
              }
            >
              <RadioGroup.Label as="span">{option.label}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  )
}

export default GradePicker
