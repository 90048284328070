import React, { useContext } from 'react'
import { graphql } from 'gatsby'

import { LanguageContext } from '../../../context/LanguageContext'
import FindMySchoolWizard from '../../FindMySchoolWizard'
import { Paragraph__Find_My_School_Wizard } from '../../../types/generated'

export const ParagraphFindMySchoolWizardFields = graphql`
  fragment ParagraphFindMySchoolWizardFields on paragraph__find_my_school_wizard {
    __typename
    id
    drupal_id
  }
`

const ParagraphFindMySchoolWizard = (props: Paragraph__Find_My_School_Wizard) => {
  const { drupal_id: paragraphId } = props
  const { langcode } = useContext(LanguageContext)

  return <FindMySchoolWizard langcode={langcode} paragraphId={paragraphId ?? ''} />
}

export default ParagraphFindMySchoolWizard
