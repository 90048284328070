import React from 'react'

interface FormPanelProps {
  children: React.ReactNode
  idx: number
  currentStep: number
  title: string
  question: string
  onNextStep: any
}

const FormPanel: React.FC<FormPanelProps> = ({
  children,
  idx,
  currentStep,
  title,
  question,
  onNextStep,
}) => {
  const display = idx === currentStep ? 'flex' : 'hidden'

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onNextStep()
  }

  return (
    <div className={`items-center justify-center py-12 px-4 md:px-32 ${display}`}>
      <div className="w-full flex flex-col justify-center">
        <p className="text-center text-gray-400 uppercase">{title}</p>
        <p className="text-center my-6 text-sdusd-blue text-xl md:text-2xl">{question}</p>

        <form onSubmit={handleSubmit}>{children}</form>
      </div>
    </div>
  )
}

export default FormPanel
