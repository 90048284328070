import algoliasearch from 'algoliasearch'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import Button from './button'
import FormPanel from './formPanel'
import GradePicker from './gradePicker'
import Input from './input'
import InterestsPicker from './interestsPicker'
import Navigation from './navigation'
import SchoolPreview from '../SchoolPreview'
import RenderIf from '../RenderIf'
import { NodeSchoolFieldsFragment } from '../../types/generated'
import { LanguageCode } from '../../types/LanguageCode'
// @ts-ignore
import { translate } from '../../translate'
import { Environment, getEnvironment } from '../../utils'

const algoliaSearchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID || '29TNHH9D6B',
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY || 'e0c57d18a902cea00ca3ba029f92770b',
)

interface FindMySchoolWizardProps {
  langcode: LanguageCode
  paragraphId: string
}

const FindMySchoolWizard: React.FC<FindMySchoolWizardProps> = ({ langcode, paragraphId }) => {
  const t = (text: string) => translate(langcode, text)

  const [currentStep, setCurrentStep] = useState(0)
  const [lastStepCompleted, setLastStepCompleted] = useState<number>(-1)

  const [formAddress, setFormAddress] = useState('')
  const [formGrade, setFormGrade] = useState('')
  const [formInterests, setFormInterests] = useState<Array<string>>([])
  const [formOther, setFormOther] = useState<Array<string>>([])

  const [specialtiesFacet, setSpecialtiesFacet] = useState<{ value: string; label: string }[]>([])
  const [additionalFeaturesFacet, setAdditionalFeaturesFacet] = useState<
    { value: string; label: string }[]
  >([])
  const [loading, setLoading] = useState(false)
  const [searchRequested, setSearchRequested] = useState(false)

  const [schoolByAddress, setSchoolByAddress] = useState<number[]>([])
  const [schoolByGrade, setSchoolByGrade] = useState<NodeSchoolFieldsFragment[]>([])
  const [recommendedSchools, setRecommendedSchools] = useState<NodeSchoolFieldsFragment[]>([])
  const [searchResults, setSearchResults] = useState<NodeSchoolFieldsFragment[]>([])

  const handleNextStep = (nextStep: number) => {
    setCurrentStep(nextStep)
    setLastStepCompleted(Math.max(nextStep, lastStepCompleted))
  }

  const handleAddressSubmit = async () => {
    handleNextStep(1)
    setSchoolByAddress([])
    try {
      const body = {
        address: formAddress,
        paragraphId,
      }
      const response = await fetch('/api/school-finder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      const schoolsResult = await response.json()
      setSchoolByAddress(schoolsResult?.result || [])
    } catch (err) {
      //
    }
  }

  const handleSearchByGrades = async () => {
    const facetFilters = [[`field_grades:${formGrade}`], [`langcode:${langcode}`]]

    const search = await algoliaSearchClient.multipleQueries<NodeSchoolFieldsFragment>([
      {
        indexName: getEnvironment() === Environment.PRODUCTION ? 'prod_schools' : 'dev_schools',
        query: '',
        params: {
          hitsPerPage: 100,
          facetFilters,
        },
      },
    ])

    setSchoolByGrade(search?.results[0]?.hits || [])
  }

  const handleGradeSubmit = async () => {
    handleNextStep(2)

    await handleSearchByGrades()
  }

  const handleSearch = async () => {
    const facetFilters = [[`field_grades:${formGrade}`], [`langcode:${langcode}`]]
    if (formInterests) {
      facetFilters.push(
        formInterests.map(
          (interest: string) => `relationships.field_specialties.title:${interest}`,
        ),
      )
    }
    if (formOther) {
      facetFilters.push(formOther.map((feature: string) => `additionalFeatures:${feature}`))
    }

    setLoading(true)
    setSearchRequested(true)
    const search = await algoliaSearchClient.multipleQueries<NodeSchoolFieldsFragment>([
      {
        indexName: getEnvironment() === Environment.PRODUCTION ? 'prod_schools' : 'dev_schools',
        query: '',
        params: {
          facetFilters,
          hitsPerPage: 100,
          page: 0,
        },
      },
    ])

    setLoading(false)
    setSearchResults(search?.results[0]?.hits || [])
    handleNextStep(4)
  }

  useEffect(() => {
    async function fetchFacets() {
      const specialtiesFacetResult =
        (await algoliaSearchClient.searchForFacetValues([
          {
            indexName: getEnvironment() === Environment.PRODUCTION ? 'prod_schools' : 'dev_schools',
            params: {
              facetName: 'relationships.field_specialties.title',
              facetQuery: '',
              hitsPerPage: 100,
              filters: `langcode:${langcode}`,
            },
          },
        ])) || []
      const mappedSpecialtiesFacet = specialtiesFacetResult[0]?.facetHits?.map((facet) => ({
        value: facet.value,
        label: facet.value,
      }))
      setSpecialtiesFacet(mappedSpecialtiesFacet ?? [])

      const additionalFeaturesFacetResult =
        (await algoliaSearchClient.searchForFacetValues([
          {
            indexName: getEnvironment() === Environment.PRODUCTION ? 'prod_schools' : 'dev_schools',
            params: {
              facetName: 'additionalFeatures',
              facetQuery: '',
              hitsPerPage: 100,
              filters: `langcode:${langcode}`,
            },
          },
        ])) || []

      const mappedAdditionalFeaturesFacet = additionalFeaturesFacetResult[0]?.facetHits?.map(
        (facet) => ({
          value: facet.value,
          label: facet.value,
        }),
      )
      setAdditionalFeaturesFacet(mappedAdditionalFeaturesFacet ?? [])
    }

    if (algoliaSearchClient) {
      fetchFacets()
    }
  }, [algoliaSearchClient])

  useEffect(() => {
    const recommended = schoolByGrade.filter((school) =>
      schoolByAddress.includes(school?.drupal_internal__nid ?? -1),
    )

    setRecommendedSchools(recommended)
  }, [schoolByAddress, schoolByGrade])

  return (
    <div>
      <Navigation
        currentStep={currentStep}
        lastStepCompleted={lastStepCompleted}
        langcode={langcode}
        setCurrentStep={setCurrentStep}
      />

      <div className="border border-gray-300 rounded-md my-2 p-2 ">
        <FormPanel
          idx={0}
          currentStep={currentStep}
          title={t('Step 1: Address')}
          question={t('What is your address?')}
          onNextStep={handleAddressSubmit}
        >
          <Input value={formAddress} onChange={(e) => setFormAddress(e.target.value)} />
          <Button>{t('Next')}</Button>
        </FormPanel>
        <FormPanel
          idx={1}
          currentStep={currentStep}
          title={t('Step 2: Grade')}
          question={t('What grade is your child?')}
          onNextStep={handleGradeSubmit}
        >
          <GradePicker grade={formGrade} setGrade={(g) => setFormGrade(g ?? '')} />
          <Button>{t('Next')}</Button>
        </FormPanel>
        <FormPanel
          idx={2}
          currentStep={currentStep}
          title={t('Step 3: Interests')}
          question={t("What are your child's interests?")}
          onNextStep={() => {
            handleNextStep(3)
          }}
        >
          <InterestsPicker
            interests={specialtiesFacet}
            formInterests={formInterests}
            setFormInterests={setFormInterests}
          />
          <Button>{t('Next')}</Button>
        </FormPanel>
        <FormPanel
          idx={3}
          currentStep={currentStep}
          title={t('Step 4: Other')}
          question={t('Any other key points for choosing your school?')}
          onNextStep={handleSearch}
        >
          <Select
            options={additionalFeaturesFacet}
            onChange={(c) => {
              setFormOther(c.map((v) => v.value))
            }}
            required
            isMulti
          />
          <Button>{loading ? t('Loading...') : t('Find my School')}</Button>
        </FormPanel>
        <FormPanel
          idx={4}
          currentStep={currentStep}
          title={`${t('Results')} (${searchResults.length + recommendedSchools?.length})`}
          question=""
          onNextStep={() => {}}
        >
          <RenderIf
            condition={
              searchRequested && (searchResults.length > 0 || recommendedSchools?.length > 0)
            }
          >
            <div className="text-center">
              <ul className="grid grid-cols gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 my-8">
                {recommendedSchools?.length > 0 && (
                  <>
                    {recommendedSchools?.map((school: NodeSchoolFieldsFragment) => (
                      <SchoolPreview key={school.id} school={school} recommendedByAddress />
                    ))}
                  </>
                )}
                {searchResults.map((school: NodeSchoolFieldsFragment) => (
                  <SchoolPreview key={school.id} school={school} />
                ))}
              </ul>
            </div>
          </RenderIf>

          <RenderIf
            condition={
              !loading &&
              searchRequested &&
              searchResults.length === 0 &&
              !recommendedSchools?.length
            }
          >
            <div className="text-center">
              <h3 className="text-xl mb-2">{t('No results to show')}</h3>
            </div>
          </RenderIf>
        </FormPanel>
      </div>
    </div>
  )
}

export default FindMySchoolWizard
