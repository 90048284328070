import React from 'react'

interface Interest {
  value: string
  label: string
}

interface InterestsPickerProps {
  interests: Interest[]
  formInterests: string[]
  setFormInterests: (interests: string[]) => void
}

const InterestsPicker: React.FC<InterestsPickerProps> = ({
  interests = [],
  formInterests,
  setFormInterests,
}) => {
  const handleChange = (e: any) => {
    const { value } = e.target
    if (formInterests.includes(value)) {
      setFormInterests(formInterests.filter((i) => i !== value))
    } else {
      setFormInterests([...formInterests, value])
    }
  }

  return (
    <fieldset>
      <div className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200">
        {interests.map((interest) => (
          <div key={interest.value} className="relative flex items-start py-4">
            <div className="min-w-0 flex-1 text-sm">
              <label
                htmlFor={`interest-${interest.value}`}
                className="block cursor-pointer select-none font-medium text-gray-700"
              >
                {interest.label}
              </label>
            </div>
            <div className="ml-3 flex h-5 items-center">
              <input
                id={`interest-${interest.value}`}
                name={`interest-${interest.value}`}
                value={interest.value}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                onChange={handleChange}
                checked={formInterests.includes(interest.value)}
              />
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  )
}

export default InterestsPicker
