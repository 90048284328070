import React from 'react'

interface ButtonProps {
  children: React.ReactNode
  loading?: boolean
}

const Button: React.FC<ButtonProps> = ({ children, loading = false }) => (
  <div className="text-center mt-6">
    <button
      type="submit"
      className="bg-sdusd-orange hover:bg-sdusd-orange-200 rounded-full px-24 py-2 text-white text-sm font-semibold"
      disabled={loading}
    >
      {children}
    </button>
  </div>
)

export default Button
