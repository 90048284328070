/* eslint no-nested-ternary: 0 */
import React from 'react'
import { CheckIcon } from '@heroicons/react/solid'

import { LanguageCode } from '../../types/LanguageCode'
// @ts-ignore
import { translate } from '../../translate'

export enum NavigationStatus {
  COMPLETE = 'complete',
  CURRENT = 'current',
  UPCOMING = 'upcoming',
}

export interface NavigationItem {
  idx: number
  position: string
  name: string
  status: NavigationStatus
}

interface NavigationProps {
  currentStep: number
  lastStepCompleted: number
  langcode: LanguageCode
  setCurrentStep: (step: number) => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Navigation: React.FC<NavigationProps> = ({
  currentStep,
  lastStepCompleted,
  langcode,
  setCurrentStep,
}) => {
  const t = (text: string) => translate(langcode, text)

  const getStatus = (idx: number): NavigationStatus => {
    if (idx === currentStep) {
      return NavigationStatus.CURRENT
    }
    if (idx < currentStep || idx <= lastStepCompleted) {
      return NavigationStatus.COMPLETE
    }
    return NavigationStatus.UPCOMING
  }

  const steps: NavigationItem[] = [
    {
      idx: 0,
      position: '01',
      name: t('Address'),
      status: getStatus(0),
    },
    {
      idx: 1,
      position: '02',
      name: t('Grade'),
      status: getStatus(1),
    },
    {
      idx: 2,
      position: '03',
      name: t('Interests'),
      status: getStatus(2),
    },
    {
      idx: 3,
      position: '04',
      name: t('Other'),
      status: getStatus(3),
    },
    {
      idx: 4,
      position: '05',
      name: t('Results'),
      status: getStatus(4),
    },
  ]

  return (
    <>
      <nav aria-label="Progress" className="md:hidden">
        <ol className="flex items-center justify-center mb-6">
          {steps.map((step, stepIdx) => (
            <li
              key={step.name}
              className={classNames(
                stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '',
                'relative',
              )}
            >
              {step.status === NavigationStatus.COMPLETE ? (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-sdusd-blue" />
                  </div>
                  <button
                    onClick={() => setCurrentStep(step.idx)}
                    className="relative flex h-8 w-8 items-center justify-center rounded-full bg-sdusd-blue hover:bg-indigo-900"
                    type="button"
                  >
                    <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    <span className="sr-only">{step.name}</span>
                  </button>
                </>
              ) : step.status === NavigationStatus.CURRENT ? (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <button
                    onClick={() => setCurrentStep(step.idx)}
                    className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-sdusd-blue bg-white"
                    aria-current="step"
                    type="button"
                  >
                    <span className="h-2.5 w-2.5 rounded-full bg-sdusd-blue" aria-hidden="true" />
                    <span className="sr-only">{step.name}</span>
                  </button>
                </>
              ) : (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <button
                    type="button"
                    disabled
                    className="group relative flex h-8 w-8 items-center cursor-not-allowed justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                  >
                    <span
                      className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{step.name}</span>
                  </button>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>

      <nav aria-label="Progress" className="hidden md:block">
        <ol className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative md:flex-1 md:flex">
              {step.status === NavigationStatus.COMPLETE ? (
                <button
                  onClick={() => setCurrentStep(step.idx)}
                  className="group flex items-center w-full cursor-pointer"
                  type="button"
                >
                  <span className="px-6 py-4 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-sdusd-blue rounded-full group-hover:bg-sdusd-purple">
                      <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                  </span>
                </button>
              ) : step.status === NavigationStatus.CURRENT ? (
                <button
                  onClick={() => setCurrentStep(step.idx)}
                  className="px-6 py-4 group flex items-center w-full text-sm font-medium"
                  aria-current="step"
                  type="button"
                >
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-sdusd-blue rounded-full">
                    <span className="text-sdusd-blue">{step.position}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-sdusd-blue">{step.name}</span>
                </button>
              ) : (
                <button
                  className="group flex items-center w-full cursor-not-allowed"
                  type="button"
                  disabled
                >
                  <span className="px-6 py-4 flex items-center w-full text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                      <span className="text-gray-500">{step.position}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500">{step.name}</span>
                  </span>
                </button>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div
                    className="hidden md:block absolute top-0 right-0 h-full w-5"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
    </>
  )
}

export default Navigation
