import React from 'react'

interface InputProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

const Input: React.FC<InputProps> = ({ value, onChange, placeholder = '' }) => (
  <input
    type="text"
    className="w-full p-2 block border border-gray-300 rounded-md"
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    required
  />
)

export default Input
